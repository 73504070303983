<template>
  <div class="ACE2">
    <div class="banner">
      <div class="title">ACE 2</div>
      <div class="osDMS w">
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 12ET7产品介绍.png" alt="" />
        </div>
        <div class="img">
          <img src="@/assets/ET 7页面配图/资源 1@4x2.png" alt="" />
        </div>
      </div>
      <div></div>
    </div>
    <div class="Display">
      <div class="main">
        <div class="title">Main Display</div>
        <div class="text">15.6"1920 x 1080</div>
        <div class="text">Capacitive Multi-Touch Screen</div>
      </div>
      <div class="secondary">
        <div class="title">Secondary Display</div>
        <div class="text textLeft">10.1"1280x800(optional)</div>
        <div class="text textLeft">10.1"1024x600(optional)</div>
      </div>
      <div class="leftLine">
        <img src="../../../assets/ACE 2/左.png" alt="" />
      </div>
      <div class="rightLine">
        <img src="../../../assets/ACE 2/右.png" alt="" />
      </div>
    </div>
    <div class="Three-axis">
      <div class="w">
        <div class="title">Three-axis foldable design</div>
        <div class="img">
          <img src="../../../assets/ACE 2/第三页配图.png" alt="" />
        </div>
      </div>
    </div>
    <div class="ACE2con">
      <div class="w">
        <div class="titleBox">
          <div class="title">ACE 2</div>
        </div>
        <div class="itemBox">
          <div class="one">
            <div class="itemA">
              <div class="title">OS</div>
              <div class="text">Windows 11/10</div>
            </div>
            <div class="itemB">
              <div class="title">Memory</div>
              <div class="text">RAM 4/8/16GB DDR</div>
              <div class="text">ROM 128/256/512GB SSD</div>
            </div>
            <div class="itemB">
              <div class="title">CPU</div>
              <div class="text">
                Intel Celeron J6412 ，Core 4th/8th/11th series
              </div>
            </div>
          </div>
          <div class="one">
            <div class="itemA">
              <div class="title">Ethernet</div>
              <div class="text">TCP/IP 1000M</div>
            </div>
            <div class="itemA">
              <div class="title">Optional</div>
              <div class="text">Face Recognition Camera</div>
              <div class="text">MSR</div>
              <div class="text">NFC</div>
            </div>
            <div class="itemA">
              <div class="title">Main Display</div>
              <div class="text">15.6” FHD 1920×1080</div>
              <div class="text">Capacitive Multi-Touch Screen</div>
            </div>
            <div class="itemA">
              <div class="title">2nd Display</div>
              <div class="text">10.1” 1280×800（optional）</div>
              <div class="text">10.1” 1024×600（optional）</div>
            </div>
            <div class="itemA">
              <div class="title">Display Interface</div>
              <div class="text">1 x HDMI/VGA</div>
            </div>
          </div>
          <div class="two">
            <div class="left">
              <div class="itemA">
                <div class="title">Power Interface</div>
                <div class="text">1 x φ5.5mm DC Jack</div>
              </div>
              <div class="itemA">
                <div class="title">Audio</div>
                <div class="text">2 x Speaker (2W)</div>
              </div>
              <div class="itemA">
                <div class="title">Wireless PAN</div>
                <div class="text">2.4G/5GHz dual-band Wi-Fi</div>
                <div class="text">IEEE 802.11 a/b/g/n</div>
              </div>
              <div class="itemA">
                <div class="title">Notification</div>
                <div class="text">Tone</div>
                <div class="text">Power indicator</div>
              </div>
              <div class="itemE">
                <div class="leftText">
                  <div class="title">Dimensions (L*W*H)</div>
                  <div class="text">366mm*209mm*310mm</div>
                </div>
                <div class="rightImg">
                  <div class="top">366mm</div>
                  <div class="left">310mm</div>
                  <div class="bottom">209mm</div>
                  <div class="img">
                    <img src="../../../assets/ACE 2/ACE 2.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="itemD">
                <div class="title">Interface</div>
                <div class="text">6 x USB</div>
                <div class="text">1 x DB9</div>
                <div class="text">1 x 3.5mm Headphone jack</div>
                <div class="text">1 x 3.5mm Microphone interface</div>
              </div>
            </div>
            <div class="right">
              <div class="itemC">
                <div class="title">Environment</div>
                <div class="text">Operating Temp：</div>
                <div class="text">0℃ ～+40℃</div>
                <div class="text">Storage Temp：</div>
                <div class="text">-20℃ ~ +60℃</div>
                <div class="text">Huminity：</div>
                <div class="text">5% ~ 95%RH</div>
                <div class="text">(No condensation)</div>
              </div>
            </div>
          </div>
        </div>
        <div class="downloads">
          <div class="box">
            <div class="itemA">
              <div class="title">ACE 2</div>
              <div class="text">PDF(1.06MB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/ACE_2.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div>
            <div class="itemB">
              <div class="title">ACE 2 User Manual</div>
              <div class="text">PDF(4.09MB)</div>
              <div class="icon">
                <a
                  href="https://www.compax.cc/download/manual/ACE_2_User_Manual.pdf"
                  target="_blank"
                  ><i class="el-icon-bottom"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.ACE2 {
  .banner {
    padding: 53px;
    height: 1000px;
    background: url('../../../assets/ACE 2/首页.png') no-repeat center;
    background-size: cover;
    .title {
      line-height: 86.88px;
      color: rgba(0, 92, 255, 1);
      text-align: center;
      font-size: 60px;
      font-weight: 700;
    }
    .osDMS {
      margin-top: 28px;
      padding: 0px 228px;
      display: flex;
      justify-content: space-between;
      .img {
        width: 363.12px;
        height: 73.4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .Display {
    position: relative;
    height: 1080px;
    background: url('../../../assets/ACE 2/第二页.png') no-repeat center;
    background-size: cover;
    .title {
      margin-bottom: 15px;
      font-weight: 700;
      color: rgba(0, 92, 255, 1);
      font-size: 36px;
      height: 46px;
    }
    .textLeft {
      text-align: end;
    }
    .text {
      color: rgba(255, 255, 255, 1);
      font-size: 24px;
      line-height: 35px;
    }
    .main {
      position: absolute;
      top: 119px;
      left: 421px;
    }
    .secondary {
      position: absolute;
      top: 119px;
      right: 420px;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .leftLine {
      position: absolute;
      width: 262px;
      height: 357px;
      top: 274px;
      left: 484px;
    }
    .rightLine {
      position: absolute;
      width: 262px;
      height: 216px;
      top: 274px;
      right: 484px;
    }
  }
  .Three-axis {
    background: rgba(0, 0, 0, 1);
    padding-bottom: 140px;
    .title {
      color: rgba(255, 255, 255, 1);
      padding: 115px 0px 90px 0px;
      font-size: 48px;
      font-weight: 700;
      text-align: center;
    }
    .img {
      border-radius: 20px;
      border: 2px solid rgba(128, 128, 128, 1);
      width: 100%;
      height: 675px;
      img {
        border-radius: 20px;
        width: 100%;
        height: 100%;
      }
    }
  }
  .ACE2con {
    background: rgba(0, 0, 0, 1);
    padding: 0px 11px;
    .titleBox {
      position: relative;
      font-weight: 500;
      line-height: 25px;
      color: rgba(0, 92, 255, 1);
      font-size: 36px;
      padding-bottom: 23px;
      border-bottom: 4px solid rgba(0, 92, 255, 0.5);
      &::after {
        position: absolute;
        left: 0px;
        bottom: -4px;
        content: '';
        width: 95px;
        height: 0px;
        border-bottom: 4px solid rgba(0, 92, 255, 0.5);
        background: rgba(0, 92, 255, 1);
      }
    }
    .itemBox {
      margin-top: 21px;
      padding: 0px 10px;
      .title {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        color: rgba(255, 255, 255, 1);
        margin-bottom: 5px;
      }
      .text {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(128, 128, 128, 1);
      }
      .itemA {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 220px;
        height: 140px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemB {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 459px;
        height: 140px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemC {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 220px;
        height: 400px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemD {
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 220px;
        height: 233px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(27, 32, 48, 1);
      }
      .itemE {
        position: relative;
        margin-top: 23px;
        padding: 19px 0px 0px 26px;
        width: 700px;
        height: 233px;
        opacity: 1;
        border-radius: 20px;
        background: rgba(0, 92, 255, 0.5);
        .text {
          color: rgba(255, 255, 255, 1);
        }
        .rightImg {
          color: rgba(255, 255, 255, 1);
          font-size: 12px;
          font-weight: 500;
          position: absolute;
          top: 10px;
          right: 43px;
          width: 310px;
          img {
            width: 100%;
            height: 100%;
          }
          .top {
            height: 30px;
            position: absolute;
            top: 8px;
            right: 0px;
          }
          .left {
            height: 30px;
            position: absolute;
            top: 95px;
          }
          .bottom {
            height: 30px;
            position: absolute;
            top: 200px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
      .one {
        display: flex;
        justify-content: space-between;
      }
      .two {
        display: flex;
        justify-content: space-between;
        .left {
          width: 940px;
          display: flex;
          justify-content: space-between;
          flex-flow: wrap;
        }
      }
    }
    .downloads {
      margin-top: 150px;
      .box {
        padding: 0px 10px;
        display: flex;
        .title {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 5px;
        }
        .text {
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          color: rgba(128, 128, 128, 1);
        }
        .itemA {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 220px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
        .itemB {
          margin-right: 30px;
          padding: 19px 0px 0px 26px;
          width: 251px;
          height: 140px;
          opacity: 1;
          border-radius: 20px;
          background: rgba(27, 32, 48, 1);
          .icon {
            font-size: 24px;
            border-radius: 50%;
            color: rgba(0, 92, 255, 1);
            margin-top: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36.55px;
            border: 1px solid rgba(0, 92, 255, 1);
          }
        }
      }
    }
  }
}
</style>
